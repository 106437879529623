import React, { useState } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import * as ScanditSDK from 'scandit-sdk';
import { blockScroll, unblockScroll } from '../../helpers/blockScroll';

const Scanner = ({ closeScanner, uncodedData }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isError, setIsError] = useState(null);
  const scan = useRef(null);
  const picker = useRef(null);

  useEffect(() => {
    handleScan();
    blockScroll();
    return () => {
      unblockScroll();

      if (picker.current) {
        picker.current.destroy(true);
      }
    };
  }, []);

  const handleCloseScanner = () => {
    setIsError(null);
    closeScanner();
  };

  const handleScan = () => {
    ScanditSDK.configure(process.env.REACT_APP_SCANDIT_KEY, {
      engineLocation: 'https://cdn.jsdelivr.net/npm/scandit-sdk@5.x/build/'
    })
      .then(() => {
        return ScanditSDK.BarcodePicker.create(scan.current, {
          playSoundOnScan: false,
          guiStyle: ScanditSDK.BarcodePicker.GuiStyle.NONE,
          enableCameraSwitcher: false,
          cameraAccess: true,
          scanSettings: new ScanditSDK.ScanSettings({
            enabledSymbologies: ['aztec'],
            visible: true,
            codeDuplicateFilter: 1000,
            cameraAccess: true
          })
        });
      })
      .then((barcodePicker) => {
        picker.current = barcodePicker;
        setIsLoaded(true);
        barcodePicker.on('scan', (scanResult) => {
          uncodedData(scanResult.barcodes[0].data);
        });
      })
      .catch((error) => {
        const errorName = error.name;
        const errorMessage = error.message;

        if (errorName === 'NotAllowedError') {
          if (errorMessage === 'Permission denied by system') {
            console.error('Permission denied by system');
          } else if (errorMessage === 'Permission denied') {
            setIsError('Zmień uprawnienia strony do korzystania z kamery, aby móc zeskanować');
          }
        } else if (errorName === 'NotReadableError') {
          console.error('NotReadableError');
        }
      });
  };
  return (
    <div className={`scanner-container ${isError && 'is-error'}`}>
      {isError && <div className="scanner-container__error">{isError}</div>}
      <div className="scanner">
        <div className="scanner__close" onClick={handleCloseScanner}>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21 3L3 21M3 3L21 21" stroke="#ca3434" strokeWidth="3" />
          </svg>
        </div>
        <div className={`scanner__camera ${isLoaded ? '' : 'hide'}`} ref={scan} />
      </div>
    </div>
  );
};

export default Scanner;
