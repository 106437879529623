import React, { useState, useRef } from 'react';
import AccordionItem from './AccordionItem';

const Accordion = ({ questionsAnswers }) => {
  const [activeIndex, setActiveIndex] = useState(-1);

  function onClick(index) {
    if (index === activeIndex) {
      setActiveIndex(-1);
      return;
    }

    setActiveIndex(index);
  }

  const renderedQuestionsAnswers = questionsAnswers.map((item, index) => {
    const isActive = index === activeIndex;

    return (
      <AccordionItem
        key={index}
        isActive={isActive}
        item={item}
        index={index}
        onClick={() => {
          onClick(index);
        }}
      />
    );
  });

  return (
    <div className="accordion">
      <dl className="accordion__list">{renderedQuestionsAnswers}</dl>
    </div>
  );
};

export default Accordion;
