import React from 'react';
import { isMobile } from "react-device-detect";

import scrollMeTo from '../../helpers/scrollMeTo';

import Accordion from '../Accordion';
import vehicleValuation from '../../assets/files/Wycena_wartosci_pojazdu.pdf';
import machineValuation from '../../assets/files/Wycena_wartosci_maszyny.pdf';

const FAQSection = () => {
  const questionsAnswers = [
    {
      question: 'Jakie dokumenty muszę mieć podczas oględzin?',
      answer:
        '<span class="bold-text">W przypadku wyceny pojazdu</span> niezbędne jest przedstawienie jego dokumentów rejestracyjnych, zawierających podstawowe dane o pojeździe np. dowód rejestracyjny i/lub karta pojazdu.</br>Warto również przedłożyć do wglądu dokumentację potwierdzającą historię serwisowania pojazdu np. książkę serwisową i/lub dokumentację przeprowadzonych napraw, remontów etc. Informacje te mogą wpłynąć na ustaloną wartość pojazdu.<br/><br/>' +
        '<span class="bold-text">W przypadku wyceny maszyn rejestrowanych np. ciągnik rolniczy,</span> niezbędne jest przedstawienie dokumentów rejestracyjnych, zawierających podstawowe dane np. dowód rejestracyjny.<br/><br/>' +
        '<span class="bold-text">W przypadku wyceny maszyn/urządzeń</span> warto przedłożyć oryginał Deklaracji zgodności WE/UE, dokumentację techniczno-ruchową, dokumentację przeprowadzonych napraw, remontów etc. Jeżeli wyceniana maszyna podlega obowiązkowemu dozorowi technicznemu etc. należy koniecznie przedstawić stosowną dokumentację dopuszczającą ją do użytkowania np. protokół UDT, TDT etc.'
    },
    {
      question: 'Jak długo trwają oględziny?',
      answer: 'Typowe oględziny pojazdu do wyceny trwają ok. 1 godziny.'
    },
    {
      question: 'Jak wygląda przykładowa wycena wartości pojazdu?',
      answer: `<a target="_blank" href=${vehicleValuation}>Przykładowa wycena wartości pojazdu.</a>`
    },
    {
      question: 'Jak wygląda przykładowa wycena wartości maszyny?',
      answer: `<a target="_blank" href=${machineValuation}>Przykładowa wycena wartości maszyny.</a>`
    }
  ];

  return (
    <div className="faq-section" name="faqSection">
      <div className="container">
        <h2 className="faq-section__header title">Pytania i odpowiedzi</h2>
        <div className="faq-section__accordion">
          <Accordion questionsAnswers={questionsAnswers} />
        </div>
        <button
          className="button button--primary faq-section__button"
          onClick={() => {
            scrollMeTo(isMobile ? 'scanQRCode' : 'formSection');
          }}
        >
          Zamów wycenę
        </button>
      </div>
    </div>
  );
};

export default FAQSection;
