import React from 'react';

const Loader = ({ loading, isUpload, uploading, statusRef, progressValue }) => {
  return (
    <div className={`loader-wrapper ${loading ? 'loading' : ''}`}>
      {isUpload && uploading && (
        <div className="uploading">
          <div className="progress-bar">
            <div className="progress" style={{ width: `${progressValue || 0}%` }}></div>
          </div>
          <div ref={statusRef}>Ładowanie...</div>
        </div>
      )}
      <div className="loader" />
    </div>
  );
};

export default Loader;
