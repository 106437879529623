import React from 'react';

import { ReactComponent as EmailIcon } from './../../assets/icons/email.svg';
import { ReactComponent as PhoneIcon } from './../../assets/icons/phone.svg';
import { ReactComponent as LogoFooter } from './images/logo-footer.svg';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__content">
          <div className="footer__left-section">
            <LogoFooter className="footer__logo"/>
            <a href="https://production.dekra.pl/pl/ochrona-danych/">Polityka prywatności</a>
          </div>
          <div className="footer__right-section">
            <address className="footer__address">
            </address>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
