import React from 'react';
import { isMobile } from "react-device-detect";

import scrollMeTo from '../../helpers/scrollMeTo';

const Header = () => {
  return (
    <header className="header">
      <div className="header__wrapper container-md">
        <div className="content">
          <h1 className="content__title">
            Wyceń pojazd <br /> lub maszynę w DEKRA
          </h1>
          <ul className="content__list">
            <li>Planujesz zakup pojazdu lub maszyny?</li>
            <li>Chcesz wycenić pojazd w kontekście sprzedaży lub zakupu z finansowaniem (leasing)?</li>
            <li>Zamierzasz sprzedać pojazd lub maszynę i potrzebujesz wiarygodnej wyceny?</li>
          </ul>
          <div className="content__bottom">
            <div className="content__bottom__button-section">
              <button
                className="button button--secondary button--big"
                onClick={() => {
                  scrollMeTo(isMobile ? 'scanQRCode' : 'formSection');
                }}
              >
                Zamów wycenę
              </button>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
