import React, { useState } from 'react';
import { isMobile } from 'react-device-detect'

import { ReactComponent as LogoHeader } from './images/logo-header.svg';

import scrollMeTo from '../../helpers/scrollMeTo';

const Navbar = () => {
  const [open, setOpen] = useState(false);

  return (
    <header className="navbar">
      <div className="container">
        <div className={`navbar__content ${open ? 'nav-open' : ''}`}>
          <a href="/" className="navbar__content__link">
            <LogoHeader />
          </a>
          <nav className="navigation">
            <ul>
              <li>
                <div
                  className="nav-item"
                  title="Jak to działa?"
                  onClick={() => {
                    setOpen(false);
                    scrollMeTo('howItWorkSection');
                  }}
                >
                  Jak to działa?
                </div>
              </li>
              <li>
                <div
                  className="nav-item"
                  title="Pytania i odpowiedzi"
                  onClick={() => {
                    setOpen(false);
                    scrollMeTo('faqSection');
                  }}
                >
                  Pytania i odpowiedzi
                </div>
              </li>
            </ul>
          </nav>
          <div className="cta">
            <button
              className="button button--secondary cta__button"
              title="Zamów wycenę"
              onClick={() => {
                scrollMeTo(isMobile ? 'scanQRCode' : 'formSection');
              }}
            >
              Zamów wycenę
            </button>
          </div>
          <div className="hamburger" onClick={() => setOpen(!open)}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
