import React from 'react';

const Input = ({
  errors,
  label,
  type,
  checkboxText,
  placeholder,
  validation,
  register,
  inputName,
  value,
  maxLength,
  minLength,
  classNames
}) => {
  return (
    <label className={`${type === 'checkbox' ? 'checkbox' : ''} ${!!classNames ? classNames : ''}`}>
      {label && <span className="input-label">{`${label}${Boolean(validation?.required) ? ' *' : ''}`}</span>}
      <input
        type={type}
        placeholder={placeholder || ''}
        className={errors[inputName] ? 'error' : ''}
        {...register(inputName, validation)}
        value={value}
        maxLength={maxLength}
        minLength={minLength}
        autoComplete="off"
      />
      {checkboxText && <span className="checked" />}
      <p dangerouslySetInnerHTML={{__html: checkboxText}}/>
      {checkboxText && Boolean(validation?.required) && ' *'}
      {errors[inputName] && <p className={`form__error ${type}__error`}>{errors[inputName].message}</p>}
    </label>
  );
};

export default Input;
