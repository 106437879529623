const makesList = [
  { kindId: '10', makeId: '75', makeName: 'SCION' },
  { kindId: '10', makeId: '76', makeName: 'TATA' },
  { kindId: '10', makeId: '77', makeName: 'BENTLEY' },
  { kindId: '10', makeId: '83', makeName: 'ASTON MARTIN' },
  { kindId: '10', makeId: '84', makeName: 'LTI' },
  { kindId: '10', makeId: '115', makeName: 'ALPINE' },
  { kindId: '10', makeId: '117', makeName: 'LEVC' },
  { kindId: '10', makeId: '119', makeName: 'SERES' },
  { kindId: '10', makeId: '131', makeName: 'DS' },
  { kindId: '10', makeId: '300', makeName: 'ABARTH' },
  { kindId: '10', makeId: '343', makeName: 'TESLA' },
  { kindId: '10', makeId: '350', makeName: 'LAMBORGHINI' },
  { kindId: '10', makeId: '351', makeName: 'ACURA' },
  { kindId: '10', makeId: '353', makeName: 'ALFA ROMEO' },
  { kindId: '10', makeId: '354', makeName: 'AUDI' },
  { kindId: '10', makeId: '355', makeName: 'AUSTIN' },
  { kindId: '10', makeId: '356', makeName: 'BMW' },
  { kindId: '10', makeId: '357', makeName: 'BUICK' },
  { kindId: '10', makeId: '358', makeName: 'CADILLAC' },
  { kindId: '10', makeId: '359', makeName: 'CHEVROLET (USA)' },
  { kindId: '10', makeId: '360', makeName: 'CHRYSLER' },
  { kindId: '10', makeId: '361', makeName: 'CITROEN' },
  { kindId: '10', makeId: '362', makeName: 'DACIA' },
  { kindId: '10', makeId: '364', makeName: 'DAEWOO / FSO' },
  { kindId: '10', makeId: '365', makeName: 'DAIHATSU' },
  { kindId: '10', makeId: '366', makeName: 'DODGE' },
  { kindId: '10', makeId: '367', makeName: 'EAGLE' },
  { kindId: '10', makeId: '368', makeName: 'FIAT' },
  { kindId: '10', makeId: '369', makeName: 'FORD' },
  { kindId: '10', makeId: '370', makeName: 'FORD (USA)' },
  { kindId: '10', makeId: '371', makeName: 'FSM' },
  { kindId: '10', makeId: '372', makeName: 'FSO' },
  { kindId: '10', makeId: '373', makeName: 'HONDA' },
  { kindId: '10', makeId: '374', makeName: 'HYUNDAI' },
  { kindId: '10', makeId: '375', makeName: 'INNOCENTI' },
  { kindId: '10', makeId: '376', makeName: 'ISUZU' },
  { kindId: '10', makeId: '377', makeName: 'JAGUAR' },
  { kindId: '10', makeId: '379', makeName: 'KIA' },
  { kindId: '10', makeId: '380', makeName: 'LADA' },
  { kindId: '10', makeId: '381', makeName: 'LANCIA' },
  { kindId: '10', makeId: '382', makeName: 'LEXUS' },
  { kindId: '10', makeId: '383', makeName: 'LINCOLN' },
  { kindId: '10', makeId: '384', makeName: 'LOTUS' },
  { kindId: '10', makeId: '385', makeName: 'MARUTI' },
  { kindId: '10', makeId: '386', makeName: 'MAZDA' },
  { kindId: '10', makeId: '387', makeName: 'MERCEDES-BENZ' },
  { kindId: '10', makeId: '388', makeName: 'MERCURY' },
  { kindId: '10', makeId: '389', makeName: 'MG' },
  { kindId: '10', makeId: '390', makeName: 'MITSUBISHI' },
  { kindId: '10', makeId: '391', makeName: 'MOSKWICZ' },
  { kindId: '10', makeId: '392', makeName: 'NISSAN' },
  { kindId: '10', makeId: '393', makeName: 'OLDSMOBILE' },
  { kindId: '10', makeId: '394', makeName: 'OLTCIT' },
  { kindId: '10', makeId: '395', makeName: 'OPEL' },
  { kindId: '10', makeId: '396', makeName: 'PEUGEOT' },
  { kindId: '10', makeId: '397', makeName: 'PLYMOUTH' },
  { kindId: '10', makeId: '398', makeName: 'PONTIAC' },
  { kindId: '10', makeId: '399', makeName: 'PORSCHE' },
  { kindId: '10', makeId: '401', makeName: 'RENAULT' },
  { kindId: '10', makeId: '402', makeName: 'ROVER' },
  { kindId: '10', makeId: '403', makeName: 'SAAB' },
  { kindId: '10', makeId: '404', makeName: 'SATURN' },
  { kindId: '10', makeId: '405', makeName: 'SEAT' },
  { kindId: '10', makeId: '406', makeName: 'SKODA' },
  { kindId: '10', makeId: '407', makeName: 'SUBARU' },
  { kindId: '10', makeId: '408', makeName: 'SUZUKI' },
  { kindId: '10', makeId: '409', makeName: 'TALBOT' },
  { kindId: '10', makeId: '410', makeName: 'TOYOTA' },
  { kindId: '10', makeId: '411', makeName: 'TRABANT' },
  { kindId: '10', makeId: '412', makeName: 'VOLKSWAGEN' },
  { kindId: '10', makeId: '413', makeName: 'VOLVO' },
  { kindId: '10', makeId: '414', makeName: 'WARTBURG' },
  { kindId: '10', makeId: '415', makeName: 'YUGO' },
  { kindId: '10', makeId: '416', makeName: 'ZAZ' },
  { kindId: '10', makeId: '444', makeName: 'MASERATI' },
  { kindId: '10', makeId: '450', makeName: 'CUPRA' },
  { kindId: '10', makeId: '460', makeName: 'FERRARI' },
  { kindId: '10', makeId: '500', makeName: 'ROLLS-ROYCE' },
  { kindId: '10', makeId: '501', makeName: 'SMART' },
  { kindId: '10', makeId: '506', makeName: 'TOYOTA (USA)' },
  { kindId: '10', makeId: '509', makeName: 'MINI [BMW]' },
  { kindId: '10', makeId: '510', makeName: 'INFINITI' },
  { kindId: '10', makeId: '516', makeName: 'CHEVROLET' },
  { kindId: '10', makeId: '524', makeName: 'SSANG YONG' },
  { kindId: '20', makeId: '17', makeName: 'JMC' },
  { kindId: '20', makeId: '76', makeName: 'TATA' },
  { kindId: '20', makeId: '77', makeName: 'BENTLEY' },
  { kindId: '20', makeId: '100', makeName: 'HUMMER' },
  { kindId: '20', makeId: '110', makeName: 'UAZ' },
  { kindId: '20', makeId: '351', makeName: 'ACURA' },
  { kindId: '20', makeId: '353', makeName: 'ALFA ROMEO' },
  { kindId: '20', makeId: '354', makeName: 'AUDI' },
  { kindId: '20', makeId: '356', makeName: 'BMW' },
  { kindId: '20', makeId: '358', makeName: 'CADILLAC' },
  { kindId: '20', makeId: '360', makeName: 'CHRYSLER' },
  { kindId: '20', makeId: '361', makeName: 'CITROEN' },
  { kindId: '20', makeId: '365', makeName: 'DAIHATSU' },
  { kindId: '20', makeId: '366', makeName: 'DODGE' },
  { kindId: '20', makeId: '368', makeName: 'FIAT' },
  { kindId: '20', makeId: '369', makeName: 'FORD' },
  { kindId: '20', makeId: '373', makeName: 'HONDA' },
  { kindId: '20', makeId: '374', makeName: 'HYUNDAI' },
  { kindId: '20', makeId: '376', makeName: 'ISUZU' },
  { kindId: '20', makeId: '377', makeName: 'JAGUAR' },
  { kindId: '20', makeId: '378', makeName: 'JEEP' },
  { kindId: '20', makeId: '379', makeName: 'KIA' },
  { kindId: '20', makeId: '380', makeName: 'LADA' },
  { kindId: '20', makeId: '382', makeName: 'LEXUS' },
  { kindId: '20', makeId: '383', makeName: 'LINCOLN' },
  { kindId: '20', makeId: '386', makeName: 'MAZDA' },
  { kindId: '20', makeId: '387', makeName: 'MERCEDES-BENZ' },
  { kindId: '20', makeId: '390', makeName: 'MITSUBISHI' },
  { kindId: '20', makeId: '392', makeName: 'NISSAN' },
  { kindId: '20', makeId: '395', makeName: 'OPEL' },
  { kindId: '20', makeId: '396', makeName: 'PEUGEOT' },
  { kindId: '20', makeId: '399', makeName: 'PORSCHE' },
  { kindId: '20', makeId: '401', makeName: 'RENAULT' },
  { kindId: '20', makeId: '403', makeName: 'SAAB' },
  { kindId: '20', makeId: '405', makeName: 'SEAT' },
  { kindId: '20', makeId: '406', makeName: 'SKODA' },
  { kindId: '20', makeId: '407', makeName: 'SUBARU' },
  { kindId: '20', makeId: '408', makeName: 'SUZUKI' },
  { kindId: '20', makeId: '410', makeName: 'TOYOTA' },
  { kindId: '20', makeId: '412', makeName: 'VOLKSWAGEN' },
  { kindId: '20', makeId: '413', makeName: 'VOLVO' },
  { kindId: '20', makeId: '419', makeName: 'GMC' },
  { kindId: '20', makeId: '421', makeName: 'RAM' },
  { kindId: '20', makeId: '428', makeName: 'LAND ROVER' },
  { kindId: '20', makeId: '438', makeName: 'IVECO' },
  { kindId: '20', makeId: '444', makeName: 'MASERATI' },
  { kindId: '20', makeId: '450', makeName: 'CUPRA' },
  { kindId: '20', makeId: '478', makeName: 'DAEWOO' },
  { kindId: '20', makeId: '510', makeName: 'INFINITI' },
  { kindId: '20', makeId: '516', makeName: 'CHEVROLET' },
  { kindId: '20', makeId: '524', makeName: 'SSANG YONG' },
  { kindId: '30', makeId: '113', makeName: 'FSR (Tarpan)' },
  { kindId: '30', makeId: '117', makeName: 'LEVC' },
  { kindId: '30', makeId: '361', makeName: 'CITROEN' },
  { kindId: '30', makeId: '362', makeName: 'DACIA' },
  { kindId: '30', makeId: '364', makeName: 'DAEWOO / FSO' },
  { kindId: '30', makeId: '365', makeName: 'DAIHATSU' },
  { kindId: '30', makeId: '368', makeName: 'FIAT' },
  { kindId: '30', makeId: '369', makeName: 'FORD' },
  { kindId: '30', makeId: '372', makeName: 'FSO' },
  { kindId: '30', makeId: '374', makeName: 'HYUNDAI' },
  { kindId: '30', makeId: '376', makeName: 'ISUZU' },
  { kindId: '30', makeId: '379', makeName: 'KIA' },
  { kindId: '30', makeId: '386', makeName: 'MAZDA' },
  { kindId: '30', makeId: '387', makeName: 'MERCEDES-BENZ' },
  { kindId: '30', makeId: '390', makeName: 'MITSUBISHI' },
  { kindId: '30', makeId: '392', makeName: 'NISSAN' },
  { kindId: '30', makeId: '395', makeName: 'OPEL' },
  { kindId: '30', makeId: '396', makeName: 'PEUGEOT' },
  { kindId: '30', makeId: '401', makeName: 'RENAULT' },
  { kindId: '30', makeId: '405', makeName: 'SEAT' },
  { kindId: '30', makeId: '406', makeName: 'SKODA' },
  { kindId: '30', makeId: '407', makeName: 'SUBARU' },
  { kindId: '30', makeId: '408', makeName: 'SUZUKI' },
  { kindId: '30', makeId: '410', makeName: 'TOYOTA' },
  { kindId: '30', makeId: '412', makeName: 'VOLKSWAGEN' },
  { kindId: '30', makeId: '438', makeName: 'IVECO' },
  { kindId: '30', makeId: '455', makeName: 'DAEWOO/ANDORIA/INTRALL' },
  { kindId: '30', makeId: '465', makeName: 'MAN' },
  { kindId: '30', makeId: '483', makeName: 'LDV' },
  { kindId: '30', makeId: '493', makeName: 'FS LUBLIN' },
  { kindId: '30', makeId: '497', makeName: 'GAZ' },
  { kindId: '30', makeId: '518', makeName: 'MITSUBISHI-FUSO / FUSO' },
  { kindId: '30', makeId: '520', makeName: 'INTRALL' },
  { kindId: '40', makeId: '369', makeName: 'FORD' },
  { kindId: '40', makeId: '374', makeName: 'HYUNDAI' },
  { kindId: '40', makeId: '376', makeName: 'ISUZU' },
  { kindId: '40', makeId: '387', makeName: 'MERCEDES-BENZ' },
  { kindId: '40', makeId: '390', makeName: 'MITSUBISHI' },
  { kindId: '40', makeId: '392', makeName: 'NISSAN' },
  { kindId: '40', makeId: '401', makeName: 'RENAULT' },
  { kindId: '40', makeId: '410', makeName: 'TOYOTA' },
  { kindId: '40', makeId: '412', makeName: 'VOLKSWAGEN' },
  { kindId: '40', makeId: '413', makeName: 'VOLVO' },
  { kindId: '40', makeId: '438', makeName: 'IVECO' },
  { kindId: '40', makeId: '456', makeName: 'DAF' },
  { kindId: '40', makeId: '464', makeName: 'IVECO EUROCARGO do 2015' },
  { kindId: '40', makeId: '465', makeName: 'MAN' },
  { kindId: '40', makeId: '469', makeName: 'MULTICAR' },
  { kindId: '40', makeId: '471', makeName: 'SCANIA' },
  { kindId: '40', makeId: '482', makeName: 'KAMAZ' },
  { kindId: '40', makeId: '484', makeName: 'TATRA' },
  { kindId: '40', makeId: '485', makeName: 'JELCZ' },
  { kindId: '40', makeId: '486', makeName: 'STAR' },
  { kindId: '40', makeId: '487', makeName: 'AVIA' },
  { kindId: '40', makeId: '489', makeName: 'MAZ' },
  { kindId: '40', makeId: '499', makeName: 'STEYR' },
  { kindId: '40', makeId: '505', makeName: 'LIAZ' },
  { kindId: '40', makeId: '518', makeName: 'MITSUBISHI-FUSO / FUSO' },
  { kindId: '80', makeId: '39', makeName: 'VANHOOL' },
  { kindId: '80', makeId: '78', makeName: 'SOLARIS' },
  { kindId: '80', makeId: '80', makeName: 'OTOYOL' },
  { kindId: '80', makeId: '82', makeName: 'VDL BOVA' },
  { kindId: '80', makeId: '85', makeName: 'NEOPLAN' },
  { kindId: '80', makeId: '86', makeName: 'AUTOSAN' },
  { kindId: '80', makeId: '87', makeName: 'VDL' },
  { kindId: '80', makeId: '88', makeName: 'SOLBUS' },
  { kindId: '80', makeId: '89', makeName: 'VDL BERKHOF' },
  { kindId: '80', makeId: '90', makeName: 'EOS' },
  { kindId: '80', makeId: '91', makeName: 'SETRA' },
  { kindId: '80', makeId: '92', makeName: 'IRIZAR' },
  { kindId: '80', makeId: '95', makeName: 'KAROSA / IRISBUS' },
  { kindId: '80', makeId: '103', makeName: 'IRISBUS' },
  { kindId: '80', makeId: '104', makeName: 'SOR' },
  { kindId: '80', makeId: '369', makeName: 'FORD' },
  { kindId: '80', makeId: '376', makeName: 'ISUZU' },
  { kindId: '80', makeId: '387', makeName: 'MERCEDES-BENZ' },
  { kindId: '80', makeId: '395', makeName: 'OPEL' },
  { kindId: '80', makeId: '401', makeName: 'RENAULT' },
  { kindId: '80', makeId: '413', makeName: 'VOLVO' },
  { kindId: '80', makeId: '439', makeName: 'IVECO BUS' },
  { kindId: '80', makeId: '465', makeName: 'MAN' },
  { kindId: '80', makeId: '471', makeName: 'SCANIA' },
  { kindId: '80', makeId: '485', makeName: 'JELCZ' },
  { kindId: '60', makeId: '79', makeName: 'AEON' },
  { kindId: '60', makeId: '116', makeName: 'AJP' },
  { kindId: '60', makeId: '53', makeName: 'ALPHA' },
  { kindId: '60', makeId: '1', makeName: 'APRILIA' },
  { kindId: '60', makeId: '3', makeName: 'ATALA' },
  { kindId: '60', makeId: '46', makeName: 'BAJAJ' },
  { kindId: '60', makeId: '94', makeName: 'BAOTIAN' },
  { kindId: '60', makeId: '4', makeName: 'BENELLI' },
  { kindId: '60', makeId: '5', makeName: 'BETAMOTOR' },
  { kindId: '60', makeId: '47', makeName: 'BIMOTA' },
  { kindId: '60', makeId: '356', makeName: 'BMW' },
  { kindId: '60', makeId: '7', makeName: 'BUELL' },
  { kindId: '60', makeId: '8', makeName: 'CAGIVA' },
  { kindId: '60', makeId: '81', makeName: 'CAN-AM BOMBARDIER' },
  { kindId: '60', makeId: '52', makeName: 'CEZET' },
  { kindId: '60', makeId: '96', makeName: 'CFMOTO' },
  { kindId: '60', makeId: '67', makeName: 'CPI' },
  { kindId: '60', makeId: '9', makeName: 'DAELIM' },
  { kindId: '60', makeId: '10', makeName: 'DERBI' },
  { kindId: '60', makeId: '48', makeName: 'DNEPR' },
  { kindId: '60', makeId: '11', makeName: 'DUCATI' },
  { kindId: '60', makeId: '107', makeName: 'EGLMOTOR' },
  { kindId: '60', makeId: '118', makeName: 'F.B MONDIAL' },
  { kindId: '60', makeId: '12', makeName: 'FANTIC' },
  { kindId: '60', makeId: '734', makeName: 'FERRO' },
  { kindId: '60', makeId: '13', makeName: 'GARELLI' },
  { kindId: '60', makeId: '73', makeName: 'GAS GAS' },
  { kindId: '60', makeId: '69', makeName: 'GENERIC' },
  { kindId: '60', makeId: '14', makeName: 'GILERA' },
  { kindId: '60', makeId: '97', makeName: 'HAOTIAN' },
  { kindId: '60', makeId: '15', makeName: 'HARLEY-DAVIDSON' },
  { kindId: '60', makeId: '16', makeName: 'HERCULES' },
  { kindId: '60', makeId: '373', makeName: 'HONDA' },
  { kindId: '60', makeId: '68', makeName: 'HUSABERG' },
  { kindId: '60', makeId: '18', makeName: 'HUSQVARNA' },
  { kindId: '60', makeId: '19', makeName: 'HYOSUNG' },
  { kindId: '60', makeId: '111', makeName: 'INDIAN' },
  { kindId: '60', makeId: '20', makeName: 'ITALJET' },
  { kindId: '60', makeId: '21', makeName: 'JAWA' },
  { kindId: '60', makeId: '45', makeName: 'JIALING / ZONG-SHEN' },
  { kindId: '60', makeId: '51', makeName: 'JINCHENG' },
  { kindId: '60', makeId: '58', makeName: 'JUNAK' },
  { kindId: '60', makeId: '22', makeName: 'KAWASAKI' },
  { kindId: '60', makeId: '72', makeName: 'KEEWAY' },
  { kindId: '60', makeId: '66', makeName: 'KOMAR' },
  { kindId: '60', makeId: '23', makeName: 'KTM' },
  { kindId: '60', makeId: '24', makeName: 'KYMCO' },
  { kindId: '60', makeId: '25', makeName: 'LAVERDA' },
  { kindId: '60', makeId: '504', makeName: 'LIFAN' },
  { kindId: '60', makeId: '98', makeName: 'LINGBEN' },
  { kindId: '60', makeId: '64', makeName: 'MAL' },
  { kindId: '60', makeId: '26', makeName: 'MALAGUTI' },
  { kindId: '60', makeId: '27', makeName: 'MBK' },
  { kindId: '60', makeId: '55', makeName: 'MINSK' },
  { kindId: '60', makeId: '28', makeName: 'MORINI' },
  { kindId: '60', makeId: '6', makeName: 'MOTOBI' },
  { kindId: '60', makeId: '29', makeName: 'MOTOGUZZI' },
  { kindId: '60', makeId: '56', makeName: 'MV AGUSTA' },
  { kindId: '60', makeId: '30', makeName: 'MZ/MUZ' },
  { kindId: '60', makeId: '31', makeName: 'P.G.O.' },
  { kindId: '60', makeId: '396', makeName: 'PEUGEOT' },
  { kindId: '60', makeId: '43', makeName: 'PIAGGIO / VESPA' },
  { kindId: '60', makeId: '420', makeName: 'POLARIS' },
  { kindId: '60', makeId: '57', makeName: 'PRAGA' },
  { kindId: '60', makeId: '33', makeName: 'PUCH / HERO PUCH' },
  { kindId: '60', makeId: '99', makeName: 'QINGQI' },
  { kindId: '60', makeId: '74', makeName: 'QUEST' },
  { kindId: '60', makeId: '62', makeName: 'RIEJU' },
  { kindId: '60', makeId: '93', makeName: 'RM MOTOR' },
  { kindId: '60', makeId: '32', makeName: 'ROMET' },
  { kindId: '60', makeId: '108', makeName: 'ROUTER' },
  { kindId: '60', makeId: '49', makeName: 'ROYAL ENFIELD BULLET' },
  { kindId: '60', makeId: '34', makeName: 'SACHS' },
  { kindId: '60', makeId: '63', makeName: 'SIAMOTO' },
  { kindId: '60', makeId: '35', makeName: 'SIMSON' },
  { kindId: '60', makeId: '36', makeName: 'SOLO' },
  { kindId: '60', makeId: '38', makeName: 'SUNDIRO' },
  { kindId: '60', makeId: '408', makeName: 'SUZUKI' },
  { kindId: '60', makeId: '71', makeName: 'SYM' },
  { kindId: '60', makeId: '40', makeName: 'TGB' },
  { kindId: '60', makeId: '70', makeName: 'TM RACING' },
  { kindId: '60', makeId: '41', makeName: 'TOMOS' },
  { kindId: '60', makeId: '106', makeName: 'TOROS' },
  { kindId: '60', makeId: '42', makeName: 'TRIUMPH' },
  { kindId: '60', makeId: '50', makeName: 'URAL' },
  { kindId: '60', makeId: '61', makeName: 'VERTEMATI' },
  { kindId: '60', makeId: '112', makeName: 'VICTORY' },
  { kindId: '60', makeId: '60', makeName: 'VOR' },
  { kindId: '60', makeId: '59', makeName: 'VOXAN' },
  { kindId: '60', makeId: '65', makeName: 'WFM' },
  { kindId: '60', makeId: '44', makeName: 'YAMAHA' },
  { kindId: '60', makeId: '101', makeName: 'YIBEN' },
  { kindId: '60', makeId: '102', makeName: 'ZICO' },
  { kindId: '60', makeId: '54', makeName: 'ZIPP' },
  { kindId: '60', makeId: '120', makeName: 'ZONTES' },
  { kindId: '60', makeId: '105', makeName: 'ZUMICO' }
];

export default makesList;