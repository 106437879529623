import React, { useState } from 'react';
import Header from '../../components/Header';
import Navbar from '../../components/Navbar';
import HowItWork from '../../components/HowItWork';
import FAQSection from '../../components/FAQSection';
import Footer from '../../components/Footer';
import FormSection from '../../components/FormSection';
import UseExpertsServices from '../../components/UseExpertsServices';
import OrderPricingButtons from '../../components/OrderPricingButtons';

const Index = () => {
  const [forceResetForm, setForceResetForm] = useState(false);
  const ifFormEnabled = false;
  
  return (
    <div className="home-page">
      {!ifFormEnabled && (
        <div className="modal-container">
          <div className="modal">
            <p>
              Szanowni Państwo,<br/>
              Ze względu na dużą ilość zamówień kontraktowych czasowo wstrzymujemy przyjęcia zleceń detalicznych.
              Funkcjonalność zostanie przywrócona w styczniu.<br/>
              Przepraszamy za utrudnienia.
            </p>
          </div>
        </div>
      )}
      <Navbar/>
      <Header/>
      <UseExpertsServices />
      <OrderPricingButtons setForceResetForm={setForceResetForm}/>
      <HowItWork />
      <FAQSection />
      <FormSection forceResetForm={forceResetForm} setForceResetForm={setForceResetForm} ifFormEnabled={ifFormEnabled}/>
      <Footer />
    </div>
  );
};

export default Index;
