import React from 'react';
import { isMobile } from "react-device-detect";

import scrollMeTo from '../../helpers/scrollMeTo';

import img1 from './images/szukaj.svg';
import img2 from './images/formularz.svg';
import img3 from './images/email.svg';
import img4 from './images/przeglad.svg';
import img5 from './images/raport.svg';

const HowItWork = () => {
  const steps = [
    {
      image: img1,
      title: 'Chcesz poznać wartość pojazdu/maszyny, np. aby wziąć leasing, kredyt, sprzedać majątek firmy',
      alt: 'Dekra'
    },
    {
      image: img2,
      title: 'Wypełniasz formularz na stronie</br>i przechodzisz do płatności',
      alt: 'Dekra'
    },
    {
      image: img3,
      title: 'Dostaniesz od nas mail</br>z potwierdzeniem zamówienia',
      alt: 'Dekra'
    },
    {
      image: img4,
      title: 'W ustalonym miejscu i czasie oglądamy pojazd/maszynę. ',
      alt: 'Dekra'
    },
    {
      image: img5,
      title: 'Otrzymujesz raport wyceny na maila',
      alt: 'Dekra'
    }
  ];

  return (
    <div className="how-it-work" name="howItWorkSection">
      <div className="container">
        <h2 className="how-it-work__header title">Jak to działa?</h2>
        <div className="how-it-work__wrapper steps">
          {steps.map((step, index) => (
            <div className="step" key={index}>
              <div className="step__round">
                <div className="step__round__number">{index + 1}</div>
                <img src={step.image} alt={step.alt} />
                <div className="step__round__triple" />
              </div>
              <p className="step__title" dangerouslySetInnerHTML={{ __html: step.title }}></p>
            </div>
          ))}
        </div>
        <div
          className="button button--primary how-it-work__button"
          onClick={() => {
            scrollMeTo(isMobile ? 'scanQRCode' : 'formSection');
          }}
        >
          Zamów wycenę
        </div>
      </div>
    </div>
  );
};

export default HowItWork;
