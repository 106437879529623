import React, { useEffect, useRef, useState } from 'react';

const AccordionItem = ({ isActive, item, index, onClick }) => {
  const [height, setHeight] = useState(0)
  const accordion = useRef(null);

  useEffect(() => {
    window.setTimeout(() => {
      if (accordion.current) {
        const height = accordion.current.querySelectorAll('.accordion__desc')[0]?.scrollHeight;

        setHeight(height)
      }
    }, 333);
  })

  const showDescription = isActive ? 'show-description' : '';
  const ariaExpanded = isActive ? 'true' : 'false';
  const isExpanded = isActive ? 'expanded' : '';
  const innerStyle = {
    height: `${isActive ? height : 0}px`
  }

  return (
    <div ref={accordion} className="accordion__question" key={item.question}>
      <dt>
        <button
          aria-expanded={ariaExpanded}
          aria-controls={`accordion${index + 1}_desc`}
          data-qa="accordion__question-button"
          className={`accordion__question-button ${isExpanded}`}
          onClick={onClick}
        >
          {item.question}
        </button>
      </dt>
      <dd style={innerStyle}>
        <p
          id={`accordion${index + 1}_desc`}
          data-qa="accordion__desc"
          className={`accordion__desc ${showDescription}`}
          dangerouslySetInnerHTML={{__html: item.answer}}
        />
      </dd>
    </div>
  );
};

export default AccordionItem;
