import React from 'react';
import { useState } from 'react';

const Select = ({
  errors,
  label,
  checkboxText,
  placeholder,
  validation,
  register,
  inputName,
  value,
  reset,
  hintValues,
}) => {
  const [filteredHints, setFilteredHints] = useState([]);

  const filterMe = (e) => {
    setFilteredHints(hintValues.filter((hint) => hint.toLowerCase().startsWith(e.target.value.toLowerCase())));
    if (e.target.value === '') {
      setFilteredHints([]);
    }
  };

  const hideAutocompleteContainer=()=>{
    setTimeout(function(){
      setFilteredHints([])
    }, 500);
  }
  return (
    <label>
      {label}{label && validation.required ? ' *' : ''}
      <input
        type={'text'}
        placeholder={placeholder || ''}
        className={errors[inputName] ? 'error' : ''}
        {...register(inputName, {
          ...validation,
          onChange: (e) => {
            hintValues && filterMe(e);
          },
        })}
        onBlur={hideAutocompleteContainer}
        value={value}
        autoComplete="off"
      />
      {filteredHints.length ? (
        <>
          <div className="autocomplete-container">
            {filteredHints.map((hint, id) => (
              <div
                onClick={() => {
                  reset({ [inputName]: hint });
                  setFilteredHints([]);
                }}
                key={id}
                className="autocomplete__item"
              >
                {hint}
              </div>
            ))}
          </div>
        </>
      ) : (
        <></>
      )}
      {checkboxText}
      {checkboxText && <span className="checked" />}
      {errors[inputName] && <p className={`form__error text__error`}>{errors[inputName].message}</p>}
    </label>
  );
};

export default Select;
