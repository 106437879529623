const typeOfCarsCategory = {
  normalCars: '10',
  offRoadCars: '20',
  smallTrack: '30',
  bigTruck: '40',
  bus: '80',
  motorcycle: '60',
};

export default typeOfCarsCategory;
