import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_ENDPOINT;

export const postForm = async (data) => {
  return axios({
    method: 'POST',
    mode: 'cors',
    url: '/api/Create',
    headers: {
      'Content-Type': 'application/json'
    },
    data: data,
    validateStatus: () => true
  });
};

export const decodeDataService = async (decodedText, config) => {
  return axios({
    method: 'POST',
    mode: 'cors',
    url: `/api/Decode`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    data: { QrCodeInString: decodedText },
    ...config
  });
};
