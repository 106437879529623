import React from 'react';
import { useState } from 'react';
import { createContext } from 'react';

export const QrCodeDataContext = createContext({
  data: {},
  updateData: () => {},
});

const QrCodeDataProvider = ({ children }) => {
  const [data, setData] = useState({});
  const updateData = (newData) => {
    setData(newData);
  };
  return <QrCodeDataContext.Provider value={{ data, updateData }}>{children}</QrCodeDataContext.Provider>;
};

export default QrCodeDataProvider;
