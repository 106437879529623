import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import './assets/styles/index.scss';

import HomePage from './pages/HomePage';
import Page404 from './pages/Page404';
import QrCodeDataProvider from './providers/QrCodeDataProvider';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <QrCodeDataProvider>
          <Routes>
            <Route path="/" exact element={<HomePage />} />
            <Route path="*" element={<Page404 />} />
          </Routes>
        </QrCodeDataProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
