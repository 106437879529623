import React, { useEffect, useRef, useState, useContext } from 'react';

import Loader from '../Loader';

import scrollMeTo from '../../helpers/scrollMeTo';
import useWindowSize from '../../helpers/useWindowSize';
import { decodeDataService } from '../../services';
import { QrCodeDataContext } from '../../providers/QrCodeDataProvider';

import qr_code from '../../assets/icons/qr_code.png';
import form from '../../assets/icons/form.png';
import Scanner from '../Scanner';
import { isMobileOnly } from 'react-device-detect';

const OrderPricingButtons = ({ setForceResetForm }) => {
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [progressValue, setProgressValue] = useState(0);
  const statusRef = React.useRef();
  const qrCodeContext = useContext(QrCodeDataContext);
  const [showScanner, setShowScanner] = useState(false);

  const decodeData = async (value) => {
    setShowScanner(false);
    setLoading(true);

    let text = value;
    await decodeDataService(text)
      .then((res) => {
        setError('');
        qrCodeContext.updateData(res.data.Data);
        setForceResetForm(true);
        scrollMeTo('formSection');
      })
      .catch((err) => {
        setError('Nie udało się zeskanować zdjęcia. Wpisz dane do formularza');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="order-pricing-buttons" name="scanQRCode">
      {showScanner && <Scanner closeScanner={() => setShowScanner(false)} uncodedData={(value) => decodeData(value)} />}
      <Loader
        loading={loading}
        isUpload={true}
        uploading={uploading}
        statusRef={statusRef}
        progressValue={progressValue}
      />
      <div className="container">
        <div className="order-pricing-buttons__wrapper">
          <div className="buttons-wrapper">
            {isMobileOnly && (
              <div className="button-container" id="leftButtonId">
                <div
                  className="button-container__button button button--primary button--big"
                  onClick={() => setShowScanner(true)}
                >
                  <p className="button__main-text">Zamów wycenę</p>
                </div>
                <div className="subtitle">
                  <img src={qr_code} alt="qr code" className="subtitle__icon" />
                  <p className="subtitle__text" onClick={() => setShowScanner(false)}>
                    Moge zeskanowac kod QR z dowodu rejestracyjnego
                  </p>
                </div>
              </div>
            )}

            <div className="button-container" id="rightButtonId">
              <div
                className="button-container__button button button--primary button--big"
                onClick={() => {
                  setForceResetForm(true);
                  scrollMeTo('formSection');
                }}
              >
                <p className="button__main-text">Zamów wycenę</p>
                <p className="button__second-text">Pojazdu lub maszyny</p>
              </div>
              {isMobileOnly && (
                <div className="subtitle">
                  <img src={form} alt="qr code" className="subtitle__icon" />
                  <p className="subtitle__text">Wpiszę dane do formularza</p>
                </div>
              )}
            </div>
          </div>
          {error && (
            <>
              <div className="uploading__fail">{error}</div>
            </>
          )}
          {success && (
            <>
              <div className="uploading__success">{success}</div>
            </>
          )}
        </div>
        <div className="bottom-text">
          Masz więcej pojazdów/maszyn lub maszyny/urządzenia o wartości powyżej 1,5 mln do wyceny?
          <br />
          <span>Skontaktuj się z nami:</span> &nbsp;
          <a href="mailto:ekspertyza.pl@dekra.com" className="bottom-text__mail">
            ekspertyza.pl@dekra.com
          </a>
          , &nbsp;
          <a href="tel:+48225773619" className="bottom-text__phone">
            +48.22.577-36-19
          </a>
          , &nbsp;
          <a href="tel:+48225773609" className="bottom-text__phone">
            +48.22.577.36-09
          </a>
          , &nbsp;
          <a href="tel:+48608661454" className="bottom-text__phone">
            608-661-454
          </a>
        </div>
      </div>
    </div>
  );
};

export default OrderPricingButtons;
